/* Phase 2 for
**Mav Market**
As a part of
CSE 5335-002-WEB DATA MANAGEMENT
Group 16:
*Karina Kalpesh Patel - 1001960022*
*Divya Mahesh Prabhu - 1002024205*
*Rachana Ramireddy - 1002028071* */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Optima, sans-serif;
  background-image: linear-gradient(to top, #7292b6 0%, #daf6f4fa 100%);
  background-repeat: no-repeat;
  opacity: .95;
  min-height: 100vh;
  position: relative;
}

/* @media screen and (max-width: 600px) {
  .body {
    width: 100%;
    padding: 0;
    height: relative;
  }
} */

a {
  text-decoration: none;
  color: inherit;
}

.font-left {
  float: left;
  padding-left: 60px;
}

.div {
  position: absolute;
}
.nav {
  background-color:#284562;;
  color: white;
  display: flex;
  justify-content: space-between;
  height: 85px;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}
.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

.home {
  width: 100%;
  background-image: linear-gradient(to top, #7292b6 0%, #daf6f4fa 100%);
  background-repeat: no-repeat;
  font-family: inherit;
  opacity: .95;
  /* height: relative; */
  min-height: relative;
}
.home-icon {
  width: 25vw;
  height: 40vh;
}
.home-left {
  float: left;
  font-size:30px;
  width: 50%;
  padding-left: 60px;
  padding-top: 90px;
}

.home-right {
  float: right;
  width: 40%;
  padding-top: 100px;
}

.about {
  position: relative;
  width: 100%;
}

.header {
  overflow: hidden;
  background-color: #284562;
  padding: 15px 10px;
}

.header a {
  float: left;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  font-family: inherit;
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 25px;
  font-family: inherit;
  font-weight: bold;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header ul {
  padding-left: 5px;
  padding-right: 5px;
}

.header-right {
  float: right;
}

.footer {
  overflow: hidden;
  background-color: #5e5757e0;
  color: white;
  height: auto;
  padding: 15px 10px;
  height: relative;
  bottom: 0;
  width: 100%;
}

.footer-left {
  float: left;
  padding-left: 15px;
  padding-bottom: 15px;
}

.footer-right {
  float: right;
  padding-top: 125px;
  font-size: 10px;
  padding-right: 15px;
  padding-bottom: 8px;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.page_wrpr {
  max-width: 1200px;
  margin: 0px auto;
  text-align: justify;
  font-family: inherit;
  text-justify: inter-word;
}

/* Service Page */

.infinite-photo-grid {
  width: 100%;
  height: 100%;
  max-height: 1200px;
  /* background-image: url('../images/infinite_loop2.jpg'); */
  background-size: calc(1.15 * min(100vh, 750px)) min(100vh, 750px);
  background-repeat: repeat-1.5x;
  animation: scroll 25s linear infinite;
}

@keyframes scroll {
  from {
    background-position: left calc(4.84 * min(100vh, 750px)) top 0px;
  }

  to {
    background-position: left 0px top 0px;
  }
}

/* Chat CSS */

.open-button {
  background-color: rgb(0, 35, 59);
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.close-chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

.open-chat-popup {
  display: block;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: rgb(4, 48, 74);
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

/* Chat over */

/* contact page */

.formboldmb5 {
  margin-bottom: 20px;
}

.formbold-pt-3 {
  padding-top: 12px;
}

.formboldmainwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formboldformwrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: FFFBF1;
}

.formboldformlabel {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}

.formboldformlabel2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formboldforminput {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: FFFBF1;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}

.formbold-form-input:focus {
  border-color: #14487e;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formboldbtn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  color: white;
  font-weight: 600;
  background-color: #284562;
  color: FFFBF1;
  cursor: pointer;
}

.formboldbtn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}

.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }

  .sm\:w-half {
    width: 50%;
  }

  .home-left, .home-right{
    width: 100%;
    padding: 20px;
  }
}


div.a {
  position: relative;
  width: 100%;
  height:auto;
}

div.text2 {
  width: 100%;
  padding: 60px;
  font-size: 15px;
  opacity: .95;
  text-align: justify;
}

@media screen and (max-width: 600px) {

  div.a,
  div.text2 {
    width: 100%;
    padding: 0;
  }
}

div.a1 {
  position: relative;
  width: 100%;
  min-height: 300px;
  padding: 50px;
}

div.a2 {
  position: relative;
  width: 100%;
  min-height: 300px;
  padding: 50px;
}

div.icon1 {
  padding: 30px;
  width: 40%;
  float: left
}

.icon {
  width: 100%;
  max-width: 220px;
  max-height: 220px;
  align-items: center;
}

div.t1 {
  padding-right: 25px;
  width: 60%;
  float: left;
  text-align: justify;
}

div.icon2 {
  padding-top: 30px;
  padding-left: 70px;
  float: right;
  width: 40%;
  align-content: center;
}

div.t2 {
  float: left;
  width: 60%;
  text-align: justify;
}

/* .infinite-photo-grid {
  width: 100%;
  height: 100%;
  max-height: 1200px;
  background-image: url(images/infinite_loop2.jpg);
  background-size: calc(1.15 * min(100vh, 750px)) min(100vh, 750px);
  background-repeat: repeat-1.5x;
  animation: scroll 25s linear infinite;
} */

@media screen and (max-width: 600px) {

  div.icon1,
  div.t1 {
      width: 100%;
      padding: 10px;
      align-content: center;
  }

  div.icon2,
  div.t2 {
      width: 100%;
      padding: 10px;
      align-content: center;
  }
}
