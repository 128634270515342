/* Phase 2 for
**Mav Market**
As a part of
CSE 5335-002-WEB DATA MANAGEMENT
Group 16:
*Karina Kalpesh Patel - 1001960022*
*Divya Mahesh Prabhu - 1002024205*
*Rachana Ramireddy - 1002028071* */

* {
  box-sizing: border-box;
}

body {
  background-image: linear-gradient(to top, #7292b6 0%, #daf6f4fa 100%);
  background-repeat: no-repeat;
  font-family: Optima, sans-serif;
  opacity: .95;
  min-height: 100vh;
  position: relative;
}

.content {
  margin: 1em;
}

a {
  text-decoration: none;
}

a:link{
  color: inherit;
}
a:visited{
  color: inherit;
}

.dropdown {
  color: white;
  /* float:right; */
  /* overflow: hidden; */
  position: relative;
  display: inline-block;
  padding: 16px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  float: none;
}

.dropdownContent {
  color: white;
  float: right;
  display: none;
  position:fixed;
  background-color: #3b6b9b;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  /* min-height: auto; */
}

.dropdownContent a {
  float:none;
  color: white;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdowncontent a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.left-column {
  float: left;
  width: 60%;
}

.right-column {
  float: left;
  width: 40%;
  padding-left: 20px;
}

.profile-left-column {
  float: left;
  width: 30%;
  overflow: auto;
  text-align: center;
}

.profile-right-column {
  float: right;
  width: 70%;
  padding-left: 20px;
}

.product-column {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.product-column img {
  width: 200px;
  height: 200px;
}

.product-row:after {
  content: "";
  display: table;
  clear: both;
}

.product {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 250px;
  margin: auto;
  text-align: center;
  font-family: inherit;
  background: white;
  padding: 10px;
}

.product-info {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: auto;
  text-align: center;
  font-family: inherit;
  padding: 20px;
  background-color: white;
}

.price {
  color: grey;
  font-size: 22px;
}

.product-info input[type=number] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.product button,
.product-info button {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: #284562;
  color: FFFBF1;
  cursor: pointer;
}

.product button:hover,
.product-info button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 600px) {
  .product-column {
    width: 100%;
  }
  .post-column {
    width: 100%;
  }
}

.posts {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

.posts img {
  vertical-align: middle;
  width:90%
}

.ad-content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.card {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
}

.add {
  padding: 10px;
  float: none;
}

.avicon {
  vertical-align: middle;
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  border-radius: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {

  .left-column,
  .right-column {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {

  .profile-left-column,
  .profile-right-column {
    width: 100%;
    padding: 0;
  }
}

.club-column {
  float: left;
  width: 50%;
  padding: 0 10px;
}

.club-row {
  margin: 0 -5px;
}

.club-row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .club-column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .post-column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.club {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.overlay, .ad-overlay {

  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: auto;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.post-row {
  display: flex;
}

.post-column {
  flex: 33.33%;
  padding: 5px;
  max-width: 250px;
}

.post-column:hover .overlay {
  opacity: 1;
}

.ad-container:hover .ad-overlay {
  opacity: 1;
}

table {
  width: 100%;
}

th,
td {
  text-align: center;
  padding: 8px;
}

tr {
  background-color: #80808045;
}

.ggprofile,
.ggprofile::after,
.ggprofile::before {
display: block;
box-sizing: border-box;
border: 2px solid;
border-radius: 100px
}

.ggprofile {
overflow: hidden;
transform: scale(var(--ggs,1));
width: 22px;
height: 22px;
position: relative;
}

.ggprofile::after,
.ggprofile::before {
content: "";
position: absolute;
top: 2px;
left: 5px;
width: 8px;
height: 8px
}

.ggprofile::after {
border-radius: 200px;
top: 11px;
left: 0px;
width: 18px;
height: 18px
}

.gg-add {
box-sizing: border-box;
position: relative;
display: block;
width: 22px;
height: 22px;
border: 2px solid;
transform: scale(var(--ggs,1));
border-radius: 22px
}

.gg-add::after,
.gg-add::before {
content: "";
display: block;
box-sizing: border-box;
position: absolute;
width: 10px;
height: 2px;
background: currentColor;
border-radius: 5px;
top: 8px;
left: 4px
}

.gg-add::after {
width: 2px;
height: 10px;
top: 4px;
left: 8px
}

.gg-file-add {
background:
linear-gradient(to bottom,
currentColor 5px,transparent 0)
no-repeat 2px 9px/6px 2px;
box-sizing: border-box;
position: relative;
display: block;
transform: scale(var(--ggs,1));
width: 14px;
height: 16px;
border: 2px solid transparent;
border-right: 0;
border-top: 0;
box-shadow: 0 0 0 2px;
border-radius: 1px;
border-top-right-radius: 4px;
overflow: hidden
}

.gg-file-add::after,
.gg-file-add::before {
content: "";
display: block;
box-sizing: border-box;
position: absolute;
height: 6px
}

.gg-file-add::before {
background: currentColor;
left: 4px;
width: 2px;
top: 7px
}

.gg-file-add::after {
width: 6px;
border-left: 2px solid;
border-bottom: 2px solid;
right: -1px;
top: -1px
}
